<template>
  <div>
    <AppLoader v-if="isLoading" class="text-white"></AppLoader>
    <div v-if="!isLoading">
      <h1 class="text-center font-ubuntu pb-12">
        <span class="block text-white font-light text-shadow text-3xl sm:text-4xl lg:text-5xl">PhotoSapiens are a </span>
        <span class="block text-white font-medium text-4xl sm:text-6xl lg:text-7xl text-shadow">New Species Of Humans</span>
        <span class="block text-white font-light text-shadow text-3xl sm:text-4xl lg:text-5xl">with an identity on the blockchain</span>
      </h1>
      <div class="px-4">
        <div class="transparent-block text-center rounded-md p-8">
          <div class="text-white text-lg md:text-xl lg:text-2xl pb-4">
            A limited edition of 10,000 NFTs as a prelaunch to PhotoChromic.<br>
            Join the waiting list to mint your PhotoSapien.
          </div>
          <div class="flex justify-center items-center">
            <div v-if="hasMetaMask">
              <button class="btn-content" @click="connect">Connect Metamask</button>
            </div>
            <div v-if="!hasMetaMask">
              <div class='flex flex-col items-center text-white text-shadow'>
                <div class="mb-4">You will need MetaMask to interact with this site:</div>
                <div>
                  <a href="https://metamask.io/" target="_blank" rel="noreferrer" class='border rounded p-4 block hover_pc_bg hover:border-black'>
                    <img src="@/assets/images/svg/MetaMask.svg" class="w-64 h-8" alt="metamask"/>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { onMounted, inject, ref } from "vue";
import connection from '../utils/crypto/connection'
import AppLoader from "@/components/AppLoader";

const emitter = require('tiny-emitter/instance');

export default {
  components: { AppLoader },
  props: {},
  setup()
  {

    const updateState = inject('updateState')
    const triggerConnect = inject('triggerConnect')
    const hasMetaMask = ref(false)
    const isLoading = ref(true)

    async function isConnected()
    {
      //Is connected?
      let connectedAccount = await connection.getAccount();
      if (connectedAccount) {
        emitter.emit('fire-alert', 'Connected', 'success')
        await updateState()
      }
    }

    async function connect()
    {
      await triggerConnect()
      await isConnected()
      await updateState()
    }


    onMounted(async () => {

      hasMetaMask.value = await connection.checkMetamask()
      isLoading.value = false
    })

    return { connect, hasMetaMask, isLoading }
  }


}
</script>